const init = async () => {
	// The map on a property page that shows a pin or a circle instead of the exact location 

	const apiKey = 'AIzaSyDvBaW1r7UYqTnilDpKxQEETmqqSV86oYY';
	let map;
	let timeout;

	(g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({
		key: apiKey,
		v: "weekly",
	});

	const { Map } = await google.maps.importLibrary("maps");

	$('.m-map:not(.js-map-done)').each(function() {
		const wrapper = $(this);
		const lat = parseFloat(wrapper.attr('data-latitude'));
		const lng = parseFloat(wrapper.attr('data-longitude'));
		const showPin = wrapper.attr('data-showpin') === 'true';
		const latLng = { lat, lng };
		const zoom = parseInt(wrapper.attr('data-zoom'), 10);

		// create map
		map = new Map(wrapper.get(0), {
			center: latLng,
			mapTypeId: 'roadmap',
			streetViewControl: false,
			zoom: zoom > 14 ? 14 : zoom,
			mapTypeControl: true,
			mapTypePanControl: true,
			scrollwheel: false
		});

		// add marker
		if (showPin) {
			var marker = new google.maps.Marker({
				position: latLng,
				icon: {
					url: '/images/common/map-pin.png',
					size: new google.maps.Size(29, 44),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(14.5, 44),
					scaledSize: new google.maps.Size(29, 44)
				},
				map,
				clickable: false
			});
		}

		if (!showPin) {
			var markerCircle = new google.maps.Circle({
				strokeColor: "#8bafbc",
				strokeOpacity: 1,
				strokeWeight: 2,
				fillColor: "#8bafbc",
				fillOpacity: 0.35,
				map,
				radius: 300,
				center: latLng
			});
		}

		//resize
		window.site.win.on('resize', function() {
			if (timeout != null) clearTimeout(timeout);

			timeout = setTimeout(function() {
				google.maps.event.trigger(map, 'resize');
				map.setCenter(latLng);
				timeout = null;
			}, 100);
		});

		// done
		wrapper.addClass('js-map-done');

	});
}

export default init;

